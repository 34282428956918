import reset from './reset';
import font from './font';
import color from './color';
import breakpoint from './breakpoint';
import vendor from './vendor';

const globalStyles = `
	${font.gothic.face}
	${reset}
	${vendor}

	html {
		overflow: visible;
	}

	body {
		overflow-x: hidden;
		font-family: ${font.primary.family};
		color: ${color.mineShaft};
		font-size: 24px;

		&.noscroll {
			position: fixed;
			overflow-y: hidden;
			height: 100vh;
			width: 100vw;
		}
	}

	h1 {
		font-size: 70px;
		margin-bottom: 45px;
	}

	h2 {
		font-size: 49px;
		margin-bottom: 33px;
	}

	h3, h4 {
		font-family: ${font.secondary.family};
		font-weight: ${font.secondary.book};
		font-size: 30px;
		letter-spacing: 2px;
		margin-bottom: 20px;
		text-transform: uppercase;
	}

	h4 {
		font-size: 20px;
		font-weight: normal;
		margin-bottom: 15px
	}

	h5 {
		color: ${color.capePalliser};
		font-family: ${font.secondary.family};
		font-size: 20px;
		letter-spacing: 3px;
		margin-bottom: 10px;
	}

	p {
		margin-bottom: 25px;
		&:last-child { margin-bottom: 0; }
	}

	ul {
		padding: 30px 0 30px 25px;
		list-style-type: disc;
		margin-top: -20px;
	}

	${breakpoint('medium', `
		body.noscroll {
			overflow-y: auto;
			position: static;
			height: auto;
			width: auto;
		}
	`)}
`;

export default globalStyles;
