const font = {
	primary: {
		family: 'goldenbook, Georgia, serif'
	},

	secondary: {
		family: 'mr-eaves-modern, sans-serif',
		book: 300
	},

	tertiary: {
		family: 'mrs-eaves, Georgia, serif'
	},

	gothic: {
		family: 'atsackersgothicheavy, mr-eaves-modern, sans-serif',
		face: `
			@font-face {
				font-family: 'atsackersgothicheavy';
				src: url('/at_sackers_heavy_gothic-webfont.woff2') format('woff2'),
						url('/at_sackers_heavy_gothic-webfont.woff') format('woff');
				font-weight: normal;
				font-style: normal;
			}
		`
	}
};

export default font;
