const color = {
	soapstone: '#FFFCFA',
	westar: '#DEDBD4',
	cavernPink: '#DDBCB0',
	capePalliser: '#A47449',
	hawaiianTan: '#924A15',
	edward: '#A1ACAB',
	mineShaft: '#333333',
	dune: '#2D2926',
	nileBlue: '#184A5B',
	lightGrey: '#f8f6f5',
	white: '#ffffff',
	cararra: '#EDEBE8'
};

export default color;
