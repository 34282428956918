import React from 'react';
import {Root, Routes} from 'react-static';
import {injectGlobal} from 'styled-components';
import globalStyles from '../../theme/global-styles';

// eslint-disable-next-line no-unused-expressions
injectGlobal`
	${globalStyles}
`;

const LayoutBase = () => <Root><Routes/></Root>;

export default LayoutBase;
