
import universal, { setHasBabelPlugin } from '/opt/build/repo/node_modules/react-universal-component/dist/index.js'


setHasBabelPlugin()

const universalOptions = {
  loading: () => null,
  error: props => {
    console.error(props.error);
    return <div>An error occurred loading this page's template. More information is available in the console.</div>;
  },
}

const t_0 = universal(import('../src/containers/not-found'), universalOptions)
const t_1 = universal(import('../src/containers/home'), universalOptions)
const t_2 = universal(import('../src/containers/weddings/home'), universalOptions)
const t_3 = universal(import('../src/containers/weddings/services'), universalOptions)
const t_4 = universal(import('../src/containers/weddings/page'), universalOptions)
const t_5 = universal(import('../src/containers/client-area'), universalOptions)
const t_6 = universal(import('../src/containers/weddings/team'), universalOptions)
const t_7 = universal(import('../src/containers/weddings/venues'), universalOptions)
const t_8 = universal(import('../src/containers/weddings/contact'), universalOptions)
const t_9 = universal(import('../src/containers/weddings/blog'), universalOptions)
const t_10 = universal(import('../src/containers/weddings/article'), universalOptions)
const t_11 = universal(import('../src/containers/events/home'), universalOptions)
const t_12 = universal(import('../src/containers/events/services'), universalOptions)
const t_13 = universal(import('../src/containers/events/page'), universalOptions)
const t_14 = universal(import('../src/containers/events/team'), universalOptions)
const t_15 = universal(import('../src/containers/events/venues'), universalOptions)
const t_16 = universal(import('../src/containers/events/contact'), universalOptions)
const t_17 = universal(import('../src/containers/events/blog'), universalOptions)
const t_18 = universal(import('../src/containers/events/article'), universalOptions)


// Template Map
export default {
  '../src/containers/not-found': t_0,
'../src/containers/home': t_1,
'../src/containers/weddings/home': t_2,
'../src/containers/weddings/services': t_3,
'../src/containers/weddings/page': t_4,
'../src/containers/client-area': t_5,
'../src/containers/weddings/team': t_6,
'../src/containers/weddings/venues': t_7,
'../src/containers/weddings/contact': t_8,
'../src/containers/weddings/blog': t_9,
'../src/containers/weddings/article': t_10,
'../src/containers/events/home': t_11,
'../src/containers/events/services': t_12,
'../src/containers/events/page': t_13,
'../src/containers/events/team': t_14,
'../src/containers/events/venues': t_15,
'../src/containers/events/contact': t_16,
'../src/containers/events/blog': t_17,
'../src/containers/events/article': t_18
}

export const notFoundTemplate = "../src/containers/not-found"
